import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const TimelineCard = ({
    title,
    subtitle,
    rating,
    link,
    linkText,
    desc,
    layout,
    className,
}) => {
    return (
        <div className={cn("resume-single-list", className)}>
            <div
                className={cn(
                    "inner",
                    layout === 2 && "psudo-after-none psudo-after-none"
                )}
            >
                <div className="heading">
                    <div className="title">
                        <h4>{title}</h4>
                        <span>{subtitle}</span>
                    </div>
                    {link && linkText && (
                        <a target="#" href={link}>
                            <div className="date-of-time">
                                <span>{linkText}</span>
                            </div>
                        </a>
                    )}
                    {rating && (
                        <div className="date-of-time">
                            <span>{rating}</span>
                        </div>
                    )}
                </div>
                <p className="description">{desc}</p>
            </div>
        </div>
    );
};

TimelineCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    rating: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    desc: PropTypes.string.isRequired,
    className: PropTypes.string,
    layout: PropTypes.oneOf([1, 2]),
};

TimelineCard.defaultProps = {
    layout: 1,
};

export default TimelineCard;
